import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  BtnWalletConnect,
  CopyButton,
  Empty,
  Footer,
  Header,
  LoadingWeboo,
  ModalTxHash,
} from "../components/bsafu-ui";
import { Context } from "../Store";
import { useGet2, usePost } from "../hooks/hooks";
import { Tab } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { Attachment, ButtonLoading, InputNumberWithBalance, SelectToken } from "../components/Forms";
import { getAppByChainId } from "../libs/Env";
import { Dialog, Transition } from "@headlessui/react";
import { useCheckIsNeedApprove, useGetTotalDonation, useTransferToken } from "../hooks/web3hook";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function truncate(str) {
  return (str.length > 16) ? str.substr(0, 10) + '....' + str.substr(32, 35) : str;
};

const TabProjectInfo = ({ row }) => {
  return (
    <>
      <div className="p-3">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <span className="font-bold">Contract Address</span>
          </div>
          <div className="flex items-center justify-start">
            <a href={"https://bscscan.com/token/" + row.project_address}>
              {truncate(row.project_address)}
            </a>
            <CopyButton text={row.project_address} />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2 md:flex-row lg:flex-row">
          <div className="w-full md:w-1/2 lg:w-1/2">
            <div className="flex flex-row items-center gap-10 mt-3">
              <span className="flex-initial w-32 text-sm font-bold">Name</span>
              <span className="text-sm font-semibold">{row.project_name}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Symbol
              </span>
              <span className="text-sm font-semibold">
                {row.project_symbol}
              </span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Decimal
              </span>
              <span className="text-sm font-semibold">
                {row.project_decimal}
              </span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Total Supply
              </span>
              <span>
                {row.project_supply}{" "}
                <span className="text-sm font-semibold">
                  {row.project_symbol}
                </span>
              </span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Contract Verified
              </span>
              <span className="text-sm font-semibold">
                {row.is_contract_verified ? "Yes" : "No"}
              </span>
            </div>
            <div className="mt-1">
              <div className="text-sm font-bold">Deployer Address</div>
              <span className="text-sm">{row.owner_address}</span>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/2">
            <div className="flex flex-row items-center gap-10 mt-3">
              <span className="flex-initial w-32 text-sm font-bold">
                Category
              </span>
              <span className="text-sm">{row.category}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1 ">
              <span className="flex-initial w-32 text-sm font-bold">
                Transfer Count
              </span>
              <span className="text-sm">{row.transfer_count} x</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                KYC Platform
              </span>
              <span className="text-sm">{row.kyc_platform ?? "N/A"}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Audit Platform
              </span>
              <span className="text-sm">{row.audit_platform ?? "N/A"}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Deployed At
              </span>
              <span className="text-sm">N/A</span>
            </div>
            <div className="mt-1">
              <div className="text-sm font-bold">Presale Address</div>
              <span className="text-sm">{truncate(row.presale_address)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TabProjectSocmed = ({ row }) => {
  return (
    <>
      <div className="p-3">
        <div className="flex flex-col w-full gap-2 md:flex-row lg:flex-row">
          <div className="w-full md:w-1/2 lg:w-1/2">
            <div className="flex flex-col gap-1 mg:gapd10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Website
              </span>
              <span>{row.project_website}</span>
            </div>
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Telegram Group
              </span>
              <span>{row.project_telegram_group}</span>
            </div>
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Telegram Channel
              </span>
              <span>{row.project_telegram_channel}</span>
            </div>
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Twitter
              </span>
              <span>{row.project_twitter}</span>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/2">
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Facebook
              </span>
              <span>{row.project_facebook}</span>
            </div>
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Instagram
              </span>
              <span>{row.project_instagram}</span>
            </div>
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Github
              </span>
              <span>{row.project_github}</span>
            </div>
            <div className="flex flex-col gap-1 mt-1 md:gap-10 lg:gap-10 md:flex-row lg:flex-row">
              <span className="flex-initial w-32 text-sm font-bold">
                Discord
              </span>
              <span>{row.project_discord}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TabProjectOwner = ({ row }) => {
  return (
    <>
      <div className="p-3">
        <div className="flex flex-row w-full">
          <div className="w-full">
            <div className="flex flex-row items-center gap-10">
              <span className="flex-initial w-32 text-sm font-bold">Name</span>
              <span>{row.owner_name ?? "N/A"}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Gender
              </span>
              <span>{row.owner_gender ?? "N/A"}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Date Of Birth
              </span>
              <span>{row.owner_dob ?? "N/A"}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Nationality
              </span>
              <span>{row.owner_nationality ?? "N/A"}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Address
              </span>
              <span>{row.owner_address ?? "N/A"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FormDonation = ({
  coin,
  address,
  scamAddress,
  onCancel = (state) => { },
}) => {
  const [state, dispatch] = useContext(Context);
  const [amount, setAmount] = useState(0);
  const [txHash, setTxHash] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [{ amountAllowance, isNeedApprove, isLoadingCheck, txHashApprove }, fetchCheck, fecthApprove] = useCheckIsNeedApprove();
  const [{ isLoadingTransfer, txHashTransfer }, fetchTransfer] = useTransferToken();

  const onDonate = async () => {
    if (amount > 0) {
      setIsLoading(true);
      const bountyScammerContract = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).BOUNTYSCAMMER_ABI,
        getAppByChainId(state.chainId).BOUNTYSCAMMER_ADDRESS
      );
      if (coin === "BNB") {
        await bountyScammerContract.methods
          .donateWithBNB(state.web3.utils.toChecksumAddress(scamAddress))
          .send({
            from: state.account,
            value: state.web3.utils.toWei(amount),
          })
          .on("transactionHash", (hash) => {

          })
          .on("receipt", (hash) => {
            setTxHash(hash?.hash);
            setIsLoading(false);
            toast.success('Thank you for your donation', {
              position: "top-right",
              autoClose: 7000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({ type: "STOREHASH" })
          }).on('error', function (error) {
            setIsLoading(false)
            toast.error('Transaction Canceled', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        const token = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ERC20_ABI,
          state.web3.utils.toChecksumAddress(address)
        );

        let dec = await token.methods.decimals().call();
        let amountWei = (new BigNumber(amount)).multipliedBy((new BigNumber(10)).pow(dec))
        await bountyScammerContract.methods
          .donateWithOthers(
            state.web3.utils.toChecksumAddress(scamAddress),
            state.web3.utils.toChecksumAddress(address),
            amountWei.toFixed(0).toString()
          )
          .send({
            from: state.account
          })
          .on("transactionHash", (hash) => { })
          .on("receipt", (hash) => {
            setTxHash(hash);
            setIsLoading(false);
            toast.success('Thank you for your donation', {
              position: "top-right",
              autoClose: 7000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({ type: "STOREHASH" })
          }).on('error', function (error) {
            setIsLoading(false)
            toast.error('Transaction Canceled', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } else {
      toast.error('Please fill amount', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (coin != "BNB")
      fetchCheck(address, getAppByChainId(state.chainId).BOUNTYSCAMMER_ADDRESS)

  }, []);

  useEffect(() => {
    if (!txHashApprove) return;
    setTxHash(txHashApprove)
  }, [txHashApprove])

  return (
    <div className="p-3 border">
      <InputNumberWithBalance
        title={"Donation Amount"}
        address={address}
        prefix={coin}
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <div className="flex flex-row gap-1 mt-1">
        {!isNeedApprove && (
          <>
            <ButtonLoading
              title={"Donate"}
              isLoading={isLoading || isLoadingCheck}
              onClick={() => {
                onDonate();
              }}
            />
            <ButtonLoading
              title={"Cancel"}
              isLoading={isLoading || isLoadingCheck}
              onClick={() => onCancel(true)}
            />
          </>
        )}
        {isNeedApprove && (
          <>
            <ButtonLoading
              title={"Enable"}
              isLoading={isLoading || isLoadingCheck}
              onClick={() => {
                fecthApprove(address, getAppByChainId(state.chainId).BOUNTYSCAMMER_ADDRESS)
              }}
              className="bg-gray-600"
            />
            <ButtonLoading
              title={"Cancel"}
              isLoading={isLoading || isLoadingCheck}
              onClick={() => onCancel(true)}
            />
          </>
        )}

        <ModalTxHash txHash={txHash} />
      </div>
    </div>
  );
};

const FormSellInformation = ({ onSuccess = (e) => { } }) => {
  let params = useParams();
  const [{ response, error, isLoading }, fetchData, setNewUrl] = usePost("https://api-farming.webooswap.com/api/v1/bounty-scammers/sell-information")
  const [state, dispatch] = useContext(Context);
  const [telegram, setTelegram] = useState("");
  const [desc, setDesc] = useState("");
  const [attach, setAttach] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      project_address: params.id,
      sender_address: state.account,
      sender_telegram: telegram,
      sender_attachment: attach?.file,
      sender_information: desc
    }
    fetchData(data)
  }

  useEffect(() => {
    if (!response) return;
    toast.success('Thank you, we will contact you soon', {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    onSuccess(response);
  }, [response])
  return (
    <>
      <form action="#" method="POST" onSubmit={(e) => onSubmit(e)}>
        <div className="p-0">
          <div className="w-full">
            <div className="w-full mt-2">
              <label
                for="company-website"
                className="block text-sm font-medium text-gray-700"
              >
                Your Address
              </label>
              <div className="flex w-full mt-1 rounded-md shadow-sm">
                <input
                  type="text"
                  name="company-website"
                  id="company-website"
                  className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="www.example.com"
                  value={state?.account}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full mt-2">
              <label
                for="company-website"
                className="block text-sm font-medium text-gray-700"
              >
                Telegram Account
              </label>
              <div className="flex w-full mt-1 rounded-md shadow-sm">
                <input
                  type="text"
                  className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>


          <div className="mt-2">
            <label
              for="about"
              className="block text-sm font-medium text-gray-700"
            >
              What you got about this scammer?
            </label>
            <div className="mt-1">
              <textarea
                id="about"
                name="about"
                rows="3"
                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="tell us here"
                onChange={(e) => setDesc(e.target.value)}
              >{desc}</textarea>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Your Information will be verify by our team and we will contact you soon.
            </p>
          </div>

          <div className="w-full">
            <div className="w-full mt-2">
              <label
                for="company-website"
                className="block text-sm font-medium text-gray-700"
              >
                Attachment
              </label>
              <div className="flex w-full mt-1 rounded-md shadow-sm">
                <Attachment onChange={(e) => setAttach(e)} />
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 text-right bg-gray-50 sm:px-0">
          <ButtonLoading title={"Submit"} isLoading={isLoading} type={'submit'} />
        </div>
      </form>
    </>
  )
}

const BountyInformation = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [coinAddress, setCoinAddress] = useState();
  const [donationCoin, setDonationCoin] = useState();
  const [isShowSelectToken, setIsShowSelectToken] = useState(false)

  let [isOpenModal, setIsOpenModal] = useState(false);
  let params = useParams();
  const [{ amountDonation, totalContributor, isLoadingTotalDonation }, fetchTotalDonation] = useGetTotalDonation(params.id)

  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }

  let [isOpenDonate, setIsOpenDonate] = useState(false)

  function closeModalDonate() {
    setIsOpenDonate(false)
  }

  function openModalDonate() {
    setIsOpenDonate(true)
  }

  function updateDonateCoin(event) {
    setDonationCoin(event.target.value);
    console.log(donationCoin);
  }

  function updateCoinAddress(event) {
    setCoinAddress(event.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
  };

  return (
    <>
      <div className="p-4 bg-white border-2 border-gray-300 rounded-md dark:border-gray-800">
        <span className="text-xl font-bold">Bounty Information</span>
        <hr className="my-2" />
        <div className="flex flex-row items-center gap-3 mt-3">
          <span className="flex items-center gap-1 px-3 py-1 text-xs font-semibold bg-green-400 rounded-lg text-dark-500 dark:bg-red-500 dark:text-white">
            Active
          </span>
        </div>
        <div className="flex flex-col gap-1 mt-1">
          <span className="text-lg text-dark-500">Total Donation</span>
          {isLoadingTotalDonation && (
            <span className="text-lg font-bold text-green-600 animate-pulse text-dark-500">
              <p className="w-3/4 h-3 py-2 mb-3 leading-relaxed bg-gray-400 animate-pulse"></p>
            </span>
          )}
          {!isLoadingTotalDonation && (
            <span className="text-lg font-bold text-green-600 text-dark-500">
              {amountDonation} BSAFU
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1 mt-1">
          <span className="text-lg text-dark-500">Total Contributors</span>
          {isLoadingTotalDonation && (
            <span className="text-lg font-bold text-green-600 animate-pulse text-dark-500">
              <p className="w-3/4 h-3 py-2 mb-3 leading-relaxed bg-gray-400 animate-pulse"></p>
            </span>
          )}
          {!isLoadingTotalDonation && (
            <span className="text-lg font-bold text-green-600 text-dark-500">
              {totalContributor}
            </span>
          )}
        </div>
        {!isOpen && !donationCoin && (
          <div className="flex flex-row gap-1 mt-1">
            <BtnWalletConnect>
              <div className="flex flex-col w-full">
                <ButtonLoading title={"Donate"} className="w-full my-1 bg-gray-900" onClick={() => setIsOpen(true)} />
                <ButtonLoading
                  title={"Sell Information"}
                  className="w-full my-1 bg-red-700"
                  onClick={openModal}
                  isDisabled={false}
                />
              </div>
            </BtnWalletConnect>
          </div>
        )}
        {isOpen && !donationCoin && (
          <div className="p-3 border">
            <span className="font-bold">Choose Donation Coin</span>
            <ButtonLoading
              title={"Donate With BNB"}
              icon={<img src={"https://cdn.webooswap.com/storage/file/file_202201221441567442_1642862516.svg"} width={20} />}
              onClick={() => {
                setDonationCoin("BNB");
                setCoinAddress("0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c");
              }}
            />
            <ButtonLoading
              title={"Donate With BSAFU"}
              icon={<img src={"https://earn.blocksafu.com/images/icon-reward/bsafu.svg"} width={20} />}
              onClick={() => {
                setDonationCoin("BSAFU");
                setCoinAddress("0x32bFd701655EDF95809EaA5e525F0024ea571267");
              }}
            />
            <ButtonLoading
              title={"Donate With Weboo"}
              icon={<img src={"https://cdn.webooswap.com/storage/file/file_202201221439036521_1642862343.svg"} width={20} />}
              onClick={() => {
                setDonationCoin("Weboo");
                setCoinAddress("0x9A093ddcaE05496a05aC76D96d49890b528C8CE0");
              }}
            />
            <ButtonLoading
              title={"Donate With Other"}
              onClick={() => setIsShowSelectToken(true)}
            />
            <SelectToken show={isShowSelectToken} onSelect={(e) => {
              setIsShowSelectToken(false);
              setDonationCoin(e.name);
              setCoinAddress(e.address);
            }} />
          </div>
        )}
        {donationCoin && (
          <FormDonation
            coin={donationCoin}
            address={coinAddress}
            onCancel={(state) => {
              setDonationCoin(null);
              setIsOpen(false);
            }}
            scamAddress={row.project_address}
          />
        )}

        {/* <Dialog
              open={isOpen}
              onClose={setIsOpen}
              as="div"
              className={clsx (
                  "fixed inset-0 z-10 overflow-y-auto flex justify-center items-center",
                  {
                      "bg-gray-200 bg-opacity-80": isOpen === true,
                  },
              )}
          >
              <div className="flex flex-col px-4 py-8 text-center text-white bg-gray-800 w-96">
                  <Dialog.Overlay />

                  <Dialog.Title className="text-3xl text-dark-500">
                      Donation
                  </Dialog.Title>
                  <Dialog.Description className="m-2 text-xl">
                      Your donation is very priceless for Hunters
                  </Dialog.Description>

                  <p className="m-4 text-md">
                      You can choose donation 
                  </p>

                  <button
                      className="inline-flex justify-center w-full px-4 py-2 m-4 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setIsOpen(false)}
                  >
                      Deactivate
                  </button>
                  <button
                      className="inline-flex justify-center w-full px-4 py-2 m-4 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setIsOpen(false)}
                  >
                      Cancel
                  </button>
              </div>
          </Dialog> */}
      </div>



      {/* Modal Sell Information */}
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-start justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left transition-all transform bg-white shadow-xl align-start rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Form Sell Information
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Complete All Information, and BlockSAFU team will review
                      and count data price
                    </p>
                  </div>

                  <div>
                    <FormSellInformation onSuccess={(e) => closeModal()} />
                  </div>


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default function BountyScammerDetail() {
  const [{ response, isLoading }, submit] = useGet2(
    "https://api-farming.webooswap.com/api/v1/bounty-scammers",
    false
  );
  let params = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await submit(
      "https://api-farming.webooswap.com/api/v1/bounty-scammers/" + params.id
    );
  }, []);

  return (
    <>
      <Header />
      <div className="w-full mx-auto">
        <div className="w-full py-10 bg-white dark:bg-gray-800">
          {isLoading && <LoadingWeboo />}
          {!isLoading && !response && <Empty text={"No Scammer Found"} />}
          {response && !isLoading && (
            <>
              <div className="w-full px-6 md:px-20 lg:px-28">
                <div className="flex flex-row items-center">
                  <img
                    src={response.data.data[0].icon}
                    className="w-12 h-12 border border-gray-200 rounded-full"
                    alt=""
                  />
                  <span className="flex items-center gap-3 ml-3 text-2xl font-bold">
                    {response.data.data[0].project_name}{" "}
                    <span className="px-2 py-1 text-sm text-gray-700 bg-gray-300 rounded-lg">
                      ({response.data.data[0].project_symbol})
                    </span>
                  </span>
                </div>
                <div className="flex flex-row items-center gap-3 mt-3">
                  <span className="flex items-center gap-1 px-3 py-1 text-xs font-semibold text-white bg-gray-900 rounded-lg dark:bg-red-500 dark:text-white">
                    Rank #1
                  </span>
                  <span className="flex items-center gap-1 px-3 py-1 text-xs font-semibold bg-gray-300 rounded-lg text-dark-500 dark:bg-red-500 dark:text-white">
                    {response.data.data[0].network}
                  </span>
                  <span className="flex items-center gap-1 px-3 py-1 text-xs font-semibold bg-gray-300 rounded-lg text-dark-500 dark:bg-red-500 dark:text-white">
                    {response.data.data[0].category}
                  </span>
                </div>
                <div className="mt-10">
                  <div className="flex flex-col gap-2 md:flex-row lg:flex-row">
                    <div className="w-full md:w-3/4 lg:w-3/4">
                      <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 rounded-md bg-blue-900/20">
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-dark",
                                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                selected
                                  ? "bg-white shadow"
                                  : "text-dark hover:bg-white/[0.12] hover:text-blue-500"
                              )
                            }
                          >
                            Project Info
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-dark",
                                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                selected
                                  ? "bg-white shadow"
                                  : "text-dark hover:bg-white/[0.12] hover:text-blue-500"
                              )
                            }
                          >
                            Social Media
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-dark",
                                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                selected
                                  ? "bg-white shadow"
                                  : "text-dark hover:bg-white/[0.12] hover:text-blue-500"
                              )
                            }
                          >
                            Owner Information
                          </Tab>
                        </Tab.List>
                        <Tab.Panels className="mt-2">
                          <Tab.Panel
                            className={classNames(
                              "rounded-md bg-white p-3 border-2",
                              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                            )}
                          >
                            <TabProjectInfo row={response.data.data[0]} />
                          </Tab.Panel>
                          <Tab.Panel
                            className={classNames(
                              "rounded-md bg-white p-3 border-2",
                              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                            )}
                          >
                            <TabProjectSocmed row={response.data.data[0]} />
                          </Tab.Panel>
                          <Tab.Panel
                            className={classNames(
                              "rounded-md bg-white p-3 border-2",
                              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                            )}
                          >
                            <TabProjectOwner row={response.data.data[0]} />
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                    <div className="w-full md:w-1/4 lg:w-1/4">
                      <BountyInformation row={response.data.data[0]} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full px-6 my-4 md:px-20 lg:px-28">
                <div className="flex flex-col w-full p-6 bg-white border-2 border-gray-300 rounded-md">
                  <h4 className="font-bold">Comments</h4>
                  <form action="" className="mt-3">
                    <textarea
                      rows="4"
                      name="message"
                      id="message"
                      placeholder="Donation Text"
                      class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-2 px-4 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    ></textarea>
                    <div>
                      <button
                        class="hover:shadow-form rounded-md bg-[#6A64F1] py-2 px-4 text-base font-semibold text-white outline-none"
                      >
                        Send Comments
                      </button>
                    </div>
                  </form>
                  <div className="py-6">
                    {/* This is first Comment Section */}
                    <div class="flex items-center space-x-2 mb-4">
                      <div class="flex flex-shrink-0 self-start cursor-pointer">
                        <img src="https://images.unsplash.com/photo-1551122089-4e3e72477432?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cnVieXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" class="h-8 w-8 object-fill rounded-full" />
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="block">
                          <div className="w-auto px-2 pb-2 bg-gray-100 rounded-xl">
                            <div className="font-medium">
                              <a href="#" className="text-sm hover:underline">
                                <small>Nirmala</small>
                              </a>
                            </div>
                            <div className="text-xs">
                              Already donate 10.000 WEBOO
                            </div>
                          </div>
                          <div className="flex items-center justify-start w-full text-xs">
                            <div className="flex items-center justify-center px-2 space-x-1 font-semibold text-gray-700">
                              <a href="#" className="hover:underline">
                                <small>15 hour</small>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* This is end first Comment Section */}

                    {/* This is Second Comment Section */}
                    <div class="flex items-center space-x-2 mb-4">
                      <div class="flex flex-shrink-0 self-start cursor-pointer">
                        <img src="https://images.unsplash.com/photo-1551122089-4e3e72477432?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cnVieXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" class="h-8 w-8 object-fill rounded-full" />
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="block">
                          <div className="w-auto px-2 pb-2 bg-gray-100 rounded-xl">
                            <div className="font-medium">
                              <a href="#" className="text-sm hover:underline">
                                <small>Agus</small>
                              </a>
                            </div>
                            <div className="text-xs">
                              Already donate 1 BNB
                            </div>
                          </div>
                          <div className="flex items-center justify-start w-full text-xs">
                            <div className="flex items-center justify-center px-2 space-x-1 font-semibold text-gray-700">
                              <a href="#" className="hover:underline">
                                <small>15 hour</small>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* This is end Second Comment Section */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
