import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    BtnWalletConnect,
    CopyAddress,
    CopyButton,
    Empty,
    Footer,
    Header,
    LoadingWeboo,
    ModalTxHash,
} from "../components/bsafu-ui";
import { Context } from "../Store";
import { getAppByChainId } from "../libs/Env";
import axios from "axios";
import { useGet } from "../hooks/hooks";
import { unixToDate } from "../libs/WebooLib";
import { NavLink } from "react-router-dom";

function truncate(str){
  return (str.length > 16) ? str.substr(0, 10) + '....' + str.substr(32, 35) : str;
};

const RowScammer = ({name,symbol,network,address,reportedBy,icon,reportedAt}) => {
  return (
    <div className="w-full mx-auto">
      <div className="p-4 bg-white border-2 border-gray-300 dark:border-gray-800 rounded-2xl">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center font-bold">
            <img src={icon} className="w-12 border border-gray-200 rounded-full" alt="" />
            <span className="ml-2 text-lg">
              {name} ({symbol})
              <br />
              <span className="gap-1 px-3 py-1 text-xs font-semibold text-white bg-gray-900 rounded-3xl dark:bg-red-500 dark:text-white">
                {network}
              </span>
            </span>
          </div>
        </div>
        <hr className="mt-3 mb-3"/>
        <div className="flex flex-col mb-3">
          <div className="flex flex-row">
            <span className="font-bold">Address</span> 
          </div>
          <div className="flex items-center justify-between gap-2">
            <a className="text-decoration" href={"https://bscscan.com/token/"+address}>{truncate(address)}</a>
            <CopyButton text={address} />
          </div>
        </div>
        <div className="flex flex-col mb-3">
            <span className="font-bold">Reported At</span>  
            <span>{unixToDate(reportedAt)} UTC</span>
        </div>
        <div className="flex flex-col mb-3">
          <div className="flex flex-row">
            <span className="font-bold">Reported By</span>
          </div>
          <span>{truncate(reportedBy)}</span>
        </div>
        <div className="flex flex-col mb-3">
        <NavLink to={"/bounty-scammers/"+address} className={'flex items-center justify-center bg-blue-600 gap-2 py-2 my-3 px-2 text-white rounded-lg'}>
            View Detail
        </NavLink>
        </div>
      </div>
    </div>
  )
}

export default function BountyScammer() {
    const [state, dispatch] = useContext(Context);
    const [{response,isLoading,errResponse},submit] = useGet('https://api-farming.webooswap.com/api/v1/bounty-scammers',true);

    useEffect(async ()=>{
        if(state && state.web3){
            
            
        }
    },[state])
    
    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body"  style={{
                      background: 'linear-gradient(90deg,#00519d,#102a85)'
                    }}>
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                              Bounty Scammer
                            </div>
                            <div className="text-xl text-white">
                            Don't give a chance to scammers and stop being scammed. Currently, BlockSAFU provides a Scam Bounty platform which aims as a platform to find scammers.
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img src="./images/bounty-scammer-logo.svg" className="w-9/12" alt="" />
                            </div>
                        </div>
                    </section>
                </section>
                <div
                    className={
                        "py-5 w-full bg-white dark:bg-gray-900 gap-4 flex items-center justify-center"
                    }
                >
                    <button className="px-5 py-2 text-white bg-gray-900 rounded-full">
                        All Scammers
                    </button>
                    {/* <button className="px-5 py-2 text-white bg-gray-600 rounded-full">
                        My Reported
                    </button> */}
                </div>
                <div className="w-full bg-gray-100 p-7 dark:bg-gray-800 md:p-10 lg:p-10">
                    <div className="container w-full md:mx-20">
                      {isLoading && <LoadingWeboo /> }
                      {!isLoading && !response && <Empty text={"No Scammer Found"} /> }
                      {response && !isLoading &&
                        <div className="grid grid-cols-1 gap-6 align-middle lg:grid-cols-3">
                          {response.data.data.map((v,i,a)=>{
                            return (
                              <RowScammer key={v._id} name={v.project_name} icon={v?.icon} symbol={v.project_symbol} network={v.network} address={v.project_address} reportedBy={v.owner_address} reportedAt={v.createdAt} />
                            )
                          })}
                        </div>
                      }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
