import React, { Fragment } from "react";
import { Footer, Header } from "../components/bsafu-ui";

export default function BountyScammerRegister() {
  return (
    <>
      <Header />
      <div className="w-full px-32 bg-gray-100">
        <div className="px-10 py-10 bg-white dark:bg-gray-800">
          <h3 className="mb-8 text-lg font-bold leading-6 text-gray-900">
            Form Register Bounty
          </h3>
          <hr />
          <form action="#" method="POST">
            <div class="grid grid-cols-2 gap-8">
              <div>
                <h4 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
                  - Project Information
                </h4>
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="presale-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Presale Address
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="presale-address"
                        id="presale-address"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="0x"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Name
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-name"
                        id="project-name"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Name"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-supply"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Supply
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-supply"
                        id="project-supply"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Supply"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-symbol"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Symbol
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-symbol"
                        id="project-symbol"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Symbol"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Address
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-address"
                        id="project-address"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="0x"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-decimal"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Decimal
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        name="project-decimal"
                        id="project-decimal"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="18"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="network"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Network
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="network"
                        id="network"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Network"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="category"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Category
                    </label>
                    <select
                      className="block w-full px-3 py-2 text-base font-normal text-gray-700 transition ease-in-out bg-white bg-no-repeat border border-gray-400 border-solid rounded appearance-none form-select bg-clip-padding focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default Category" name="category"
                      id="category"
                    >
                      <option selected>Select Category</option>
                      <option value="DeFi">DeFi</option>
                      <option value="Meme">Meme</option>
                      <option value="DAO">DAO</option>
                      <option value="DEX">DEX</option>
                      <option value="Wallet">Wallet</option>
                      <option value="NFT">NFT</option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <h4 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
                  - Owner Information
                </h4>
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="owner-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Owner Address
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="owner-address"
                        id="owner-address"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="0x"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="owner-dob"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Owner DOB
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="owner-dob"
                        id="owner-dob"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="1975-01-01"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="owner-gender"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Owner Gender
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                    <select
                      className="block w-full px-3 py-2 text-base font-normal text-gray-700 transition ease-in-out bg-white bg-no-repeat border border-gray-400 border-solid rounded appearance-none form-select bg-clip-padding focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default Category" name="owner-gender"
                      id="owner-gender"
                    >
                      <option selected>Select Gender</option>
                      <option value="DeFi">Male</option>
                      <option value="Meme">Female</option>
                    </select>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="owner-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Owner Name
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="owner-name"
                        id="owner-name"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Owner Name"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="owner-nationality"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Owner Nationality
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="owner-nationality"
                        id="owner-nationality"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Owner Nationality"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="audit-platform"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Audit Platform
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="audit-platform"
                        id="audit-platform"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Audit Platform"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="kyc-platform"
                      className="block text-sm font-medium text-gray-700"
                    >
                      KYC Platform
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="kyc-platform"
                        id="kyc-platform"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="KYC Platform"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="mt-10 mb-2 text-lg font-medium leading-6 text-gray-900">
              - Social Media Information
            </h4>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-telegram-channel"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Telegram Channel
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-telegram-channel"
                        id="project-telegram-channel"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Telegram Channel"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-telegram-group"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Telegram Group
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-telegram-group"
                        id="project-telegram-group"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Telegram Group"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-twitter"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Twitter
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-twitter"
                        id="project-twitter"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Twitter"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Website
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-website"
                        id="project-website"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Project Website"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-discord"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Discord
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-discord"
                        id="project-discord"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="URL Discord"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-facebook"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Facebook
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-facebook"
                        id="project-facebook"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="URL Facebook"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-github"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Github
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-github"
                        id="project-github"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="URL Github"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="project-instagram"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Project Instagram
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="project-instagram"
                        id="project-instagram"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="URL Instagram"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-8 mt-10">
              <div>
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="total-scammed"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Total Scammed
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="total-scammed"
                        id="total-scammed"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Total Scammed"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label
                      for="bounty-reward"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bounty Reward
                    </label>
                    <div className="flex w-full mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="bounty-reward"
                        id="bounty-reward"
                        className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="$ 1,000,000,000"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-0"></div>
            <div className="text-left py-7 bg-gray-50 sm:px-0">
              <button
                type="submit"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Register Bounty
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
