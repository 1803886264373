import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    BtnWalletConnect,
    CopyAddress,
    Footer,
    Header,
    ModalTxHash,
} from "../components/bsafu-ui";
import { FiRefreshCw } from 'react-icons/fi'
import { BsQuestionCircle, BsChevronDown, BsChevronExpand, BsCheck } from 'react-icons/bs'

import { Disclosure, Listbox, Transition } from '@headlessui/react'
import { ButtonLoading, ButtonLoadingGray, InputNumberWithBalance } from "../components/Forms";
import { Context } from "../Store";
import { getAppByChainId } from "../libs/Env";
import { BigNumber } from "bignumber.js";
import { amountFormat, countAPY } from "../libs/WebooLib";
import axios from "axios";

const coin = [
    { name: 'BSAFU', logo: 'bsafu.svg' },
    { name: 'BUSD', logo: 'busd.svg' },
]

const FormCompounding = ({ onGetTotalStacked = (e) => { } }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [amountAllowance, setAmountAllowance] = useState(0)
    const [decimal, setDecimal] = useState(18);
    const [tokenAddress, setTokenAddress] = useState(null);
    const [amount, setAmount] = useState(0);
    const [txHash, setTxHash] = useState(null);
    const [isNeedApprove, setIsNeedApprove] = useState(false);
    const [isNeedApproveUnstake, setIsNeedApproveUnstake] = useState(false);
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);
    const [isUnstake, setIsUnstake] = useState(false);
    const [stakingAddress, setStakingAddress] = useState(null);
    const [stakingBalance, setStakingBalance] = useState(0);
    const [unclaimBalance, setUnclaimBalance] = useState(0);
    const [bsafuPrice, setBsafuPrice] = useState(0);
    const [totalStacked, setTotalStacked] = useState(0);

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            await init();
            setHash(state.storeHash);
        }
    }, [state, txHash])

    useEffect(() => {
        onGetTotalStacked(totalStacked);
    }, [totalStacked])

    const checkIfNeedEnableContract = async () => {
        try {
            if (state && state.chainId && state.account) {
                // alert("0xb39AF32036e58605bE3dD3BB91D5898c9bf76F1F")
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
                setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS);
                const scAllowance = await token.methods.allowance(
                    state.web3.utils.toHex(state.account),
                    state.web3.utils.toHex(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS)
                ).call();
                const allowance = new BigNumber(
                    scAllowance
                );
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
                const dec = await token.methods.decimals().call();
                setDecimal(dec);

                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApprove(false)
                } else {
                    setIsNeedApprove(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const checkIfNeedEnableUnstake = async () => {
        try {
            if (state && state.chainId && state.account) {
                const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
                const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, autoStakingAddress);
                const allowance = new BigNumber(await token.methods.allowance(state.account, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS).call());
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());

                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApproveUnstake(false)
                } else {
                    setIsNeedApproveUnstake(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const init = async () => {
        await checkIfNeedEnableContract();
        await checkIfNeedEnableUnstake();

        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
        const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).AUTO_STAKING_ABI, autoStakingAddress);
        const dec = await staking.methods.decimals().call();
        const totalSupply = new BigNumber(await staking.methods.totalSupply().call());
        const APR = await staking.methods.APR().call();
        const totalLoop = await staking.methods.loopInterest().call();

        // price bsafu dexguru
        await axios.post('https://api.dex.guru/v3/tokens', {
            ids: ["0x32bfd701655edf95809eaa5e525f0024ea571267-bsc"],
            limit: 1,
            network: "eth,optimism,bsc,gnosis,polygon,fantom,arbitrum,celo,avalanche"
        })
            .then(res => {
                setBsafuPrice(Number(res.data.data[0].priceETH).toFixed(8).replace(/\.?0+$/, ""))
                // let current = Number(res.data.data[0].priceETH).toFixed(8).replace(/\.?0+$/, "")
                // console.log("BSAFU Price In BNB: ", current)
            })
        // price bsafu in bnb
        // await axios.get('https://api.pancakeswap.info/api/v2/tokens/0x32bFd701655EDF95809EaA5e525F0024ea571267')
        //     .then(res => {
        //         setBsafuPrice(Number(res.data.data.price_BNB).toFixed(9))
        //         console.log("BSAFU Price In BNB: ", res.data.data.price_BNB)
        //     })
        const dividendOf = new BigNumber(await staking.methods.dividendOf(state.account).call());
        // setUnclaimBalance((amountFormat(dividendOf.dividedBy(10 ** 18), 18) / bsafuPrice))
        setUnclaimBalance(amountFormat(dividendOf.dividedBy(10 ** 18), 18))
        let stkBalance = new BigNumber(await staking.methods.balanceOf(state.account).call());
        stkBalance = stkBalance.dividedBy(10 ** dec);
        setStakingBalance(amountFormat(stkBalance, 4));
        setApr(APR);
        setApy(countAPY(APR, totalLoop));
        setStakingAddress(autoStakingAddress);
        setTotalStacked(amountFormat(totalSupply.div(10 ** dec).toString()))
    }

    const onStake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
            await routerStaking.methods.stake(autoStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On Stake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onUnstake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);
            // alert(amnt.toFixed(0))

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
            await routerStaking.methods.unstake(autoStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On UnStake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onEnable = async () => {
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
    }

    const onEnableUnstake = async () => {
        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, autoStakingAddress);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => {
                setTxHash(hash);
                setIsLoading(false);
                setIsNeedApproveUnstake(false);
            });
    }

    return (
        <>
            <div className="flex justify-between w-full py-2">
                <p className="text-gray-900 dark:text-white">APY</p>
                <p className="text-gray-900 dark:text-white">{apy}%</p>
            </div>
            <div className="flex justify-between w-full py-2">
                <p className="text-gray-900 dark:text-white">Balance (BSAFU-SP(AUTO))</p>
                <p className="font-bold text-gray-900 dark:text-white">{stakingBalance}</p>
            </div>
            <div className="flex justify-between w-full py-2">
                <p className="text-gray-900 dark:text-white">Unclaim Reward (BSAFU)</p>
                <p className="font-bold text-gray-900 dark:text-white">{unclaimBalance}</p>
            </div>

            {/* <div className="block w-full py-2">
                <p className="text-gray-900 dark:text-white">Recent BSAFU Profit:</p>
                <p className="text-gray-900 underline">0.1% unstaking fee if withdrawn within 72h</p>
            </div> */}
            <div className="block w-full py-2">
                <p className="font-semibold text-gray-900 dark:text-white">
                    {!isUnstake ? "Start Earning" : "Unstake"}
                </p>
                {/* disable button compounding */}
                {!isUnstake &&
                    <BtnWalletConnect>
                        <InputNumberWithBalance
                            title={"Amount"}
                            onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                            value={amount}
                            subFix={"BSAFU"}
                            prefix={"BSAFU"}
                            address={tokenAddress}
                            key={'bsafu' + txHash}
                        />
                        {isNeedApprove &&
                            <ButtonLoading
                                title={"Enable Contract"}
                                onClick={() => onEnable()}
                                isLoading={isLoading}
                            />
                        }
                        {!isNeedApprove &&
                            <div className="flex items-center justify-between gap-2">
                                <ButtonLoading title={"Stake"} isLoading={isLoading} onClick={() => onStake()} />
                                <ButtonLoadingGray title={"Unstake"} isLoading={isLoading} onClick={() => setIsUnstake(true)} />
                            </div>
                        }
                    </BtnWalletConnect>
                }
                {isUnstake &&
                    <BtnWalletConnect>
                        <InputNumberWithBalance
                            title={"Amounts"}
                            onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                            value={amount}
                            subFix={"BSAFU-SP(Auto)"}
                            prefix={"BSAFU-SP(Auto)"}
                            address={stakingAddress}
                            key={'bsafu-sp' + txHash}
                        />
                        {isNeedApproveUnstake &&
                            <>
                                <ButtonLoading
                                    title={"Enable Contract"}
                                    onClick={() => onEnableUnstake()}
                                    isLoading={isLoading}
                                />
                                <div className="text-center">or</div>
                                <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                            </>
                        }
                        {!isNeedApproveUnstake &&
                            <div className="flex items-center justify-between gap-2">
                                <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                                <ButtonLoading title={"Unstake"} isLoading={isLoading} onClick={() => onUnstake()} />
                            </div>
                        }
                    </BtnWalletConnect>
                }
                <ModalTxHash txHash={txHash} />
            </div>
            <div>
                <p>Note: Auto-claim will be carried out alternately with the BSAFU transaction trigger. Please be patient to get an auto-claim turn.</p>
            </div>
        </>
    )
}

const FormManualStaking = ({ onGetTotalStacked = (e) => { } }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [amountAllowance, setAmountAllowance] = useState(0)
    const [decimal, setDecimal] = useState(18);
    const [tokenAddress, setTokenAddress] = useState(null);
    const [amount, setAmount] = useState(0);
    const [txHash, setTxHash] = useState(null);
    const [isNeedApprove, setIsNeedApprove] = useState(false);
    const [isNeedApproveUnstake, setIsNeedApproveUnstake] = useState(false);
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);
    const [isUnstake, setIsUnstake] = useState(false);
    const [stakingAddress, setStakingAddress] = useState(null);
    const [totalStacked, setTotalStacked] = useState(0);
    const [amountUnclaim, setAmountUnclaim] = useState(0)
    const [stakingBalance, setStakingBalance] = useState(0);

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            await init();
            setHash(state.storeHash);
        }
    }, [state, txHash])

    useEffect(() => {
        onGetTotalStacked(totalStacked);
    }, [totalStacked])

    const checkIfNeedEnableContract = async () => {
        try {
            if (state && state.chainId) {
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
                setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS);
                const allowance = new BigNumber(await token.methods.allowance(state.account, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS).call());
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
                const dec = await token.methods.decimals().call();
                // balance = balance.div(10**dec);
                setDecimal(dec);

                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApprove(false)
                } else {
                    setIsNeedApprove(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const checkIfNeedEnableUnstake = async () => {
        try {
            if (state && state.chainId) {
                const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
                const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, manualStakingAddress);
                const allowance = new BigNumber(await token.methods.allowance(state.account, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS).call());
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
                // console.log(allowance.toString(),balance.toString());
                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApproveUnstake(false)
                } else {
                    setIsNeedApproveUnstake(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const init = async () => {
        await checkIfNeedEnableContract();
        await checkIfNeedEnableUnstake();

        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
        const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).AUTO_STAKING_ABI, manualStakingAddress);
        const dividendOf = new BigNumber(await staking.methods.dividendOf(state.account).call());

        const dec = await staking.methods.decimals().call();
        const totalSupply = new BigNumber(await staking.methods.totalSupply().call());
        const APR = await staking.methods.APR().call();
        let stkBalance = new BigNumber(await staking.methods.balanceOf(state.account).call());
        stkBalance = stkBalance.dividedBy(10 ** dec);
        setStakingBalance(amountFormat(stkBalance, 4));
        setAmountUnclaim(amountFormat(dividendOf.dividedBy(10 ** dec), 18))
        setApr(APR);
        setStakingAddress(manualStakingAddress);
        setTotalStacked(amountFormat(totalSupply.div(10 ** dec).toString()))
    }

    const onStake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            await routerStaking.methods.stake(manualStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On Stake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onUnstake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            await routerStaking.methods.unstake(manualStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On UnStake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onEnable = async () => {
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
    }

    const onEnableUnstake = async () => {
        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, manualStakingAddress);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => {
                setTxHash(hash);
                setIsLoading(false);
                setIsNeedApproveUnstake(false);
            });
    }
    return (
        <>
            <div className="flex justify-between w-full p-6 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl">
                <div className="block">
                    <h3 className="items-center text-xl font-semibold text-gray-900 dark:text-white">Simple Interest <span className="px-2 py-1 text-xs text-white bg-red-700 rounded-lg">Recommended</span></h3>
                    <p className="font-normal text-gray-700 dark:text-gray-100">Earn All Coin, Stake BSAFU </p>
                </div>
                <div className="">
                    <img src="images/icon-reward/bsafu.svg" className="border border-gray-200 rounded-full w-14" width="54" height="54" alt="" />
                    <img src="images/icon-reward/btc.svg" className="absolute z-10 w-8 ml-8 -mt-5 border border-gray-200 rounded-full" alt="" />
                </div>
            </div>
            <div className="w-full px-6 py-3 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
                <div className="flex justify-between w-full py-2">
                    <p className="text-gray-900 dark:text-white">APR</p>
                    <p className="text-gray-900 dark:text-white">{apr}%</p>
                </div>
                <div className="flex justify-between w-full py-2">
                    <p className="text-gray-900 dark:text-white">Balance (BSAFU-SP)</p>
                    <p className="font-bold text-gray-900 dark:text-white">{stakingBalance}</p>
                </div>
                <div className="flex justify-between w-full py-2">
                    <p className="text-gray-900 dark:text-white">Unclaim Reward (BNB)</p>
                    <p className="font-bold text-gray-900 dark:text-white">{amountUnclaim}</p>
                </div>
                <div className="block w-full py-2">
                    <p className="font-semibold text-gray-900 dark:text-white">Start Earning</p>
                    {/* disable button simple interest */}
                    {!isUnstake &&
                        <BtnWalletConnect>
                            <InputNumberWithBalance
                                title={"Amounts"}
                                onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                                value={amount}
                                subFix={"BSAFU"}
                                prefix={"BSAFU"}
                                address={tokenAddress}
                                key={'bsafu' + txHash}
                            />
                            {isNeedApprove &&
                                <ButtonLoading
                                    title={"Enable Contract"}
                                    onClick={() => onEnable()}
                                    isLoading={isLoading}
                                />
                            }
                            {!isNeedApprove &&
                                <div className="flex items-center justify-between gap-2">
                                    <ButtonLoading title={"Stake"} isLoading={isLoading} onClick={() => onStake()} />
                                    <ButtonLoadingGray title={"Unstake"} isLoading={isLoading} onClick={() => setIsUnstake(true)} />
                                </div>
                            }
                        </BtnWalletConnect>
                    }
                    {isUnstake &&
                        <BtnWalletConnect>
                            <InputNumberWithBalance
                                title={"Amount"}
                                onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                                value={amount}
                                subFix={"BSAFU-SP"}
                                prefix={"BSAFU-SP"}
                                address={stakingAddress}
                                key={'bsafu-sp' + txHash}
                            />
                            {isNeedApproveUnstake &&
                                <>
                                    <ButtonLoading
                                        title={"Enable Contract"}
                                        onClick={() => onEnableUnstake()}
                                        isLoading={isLoading}
                                    />
                                    <div className="text-center">or</div>
                                    <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                                </>
                            }
                            {!isNeedApproveUnstake &&
                                <div className="flex items-center justify-between gap-2">
                                    <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                                    <ButtonLoading title={"Unstake"} isLoading={isLoading} onClick={() => onUnstake()} />
                                </div>
                            }
                        </BtnWalletConnect>
                    }
                </div>
                <ModalTxHash txHash={txHash} />
            </div>
        </>
    )
}

const EarnManualStaking = () => {
    const [coinselected, setcoinSelected] = useState({ name: 'BSAFU', logo: 'bsafu.svg' })
    const [dividendBSAFU, setDividendBSAFU] = useState(0);
    const [dividendBNB, setDividendBNB] = useState(0);
    const [dividendBUSD, setDividendBUSD] = useState(0);
    const [dividendBTCB, setDividendBTCB] = useState(0);
    const [dividendBWETH, setDividendBWETH] = useState(0);
    const [dividendWeboo, setDividendWeboo] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [txHash, setTxHash] = useState(null);
    useEffect(async () => {
        if (state && state.web3 && state.account) {
            await init();
            setHash(state.storeHash);
        }
    }, [state, txHash])

    const getEstimation = async (tokenAddress, amountIn) => {
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, tokenAddress);
        const dec = await token.methods.decimals().call()
        const routerDex = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_ABI, getAppByChainId(state.chainId).ROUTER_ADDRESS);
        let path = [
            await routerDex.methods.WETH().call(),
            tokenAddress
        ];
        const est = await routerDex.methods.getAmountsOut(
            amountIn,
            path
        ).call()
        let a = new BigNumber(est[1]);
        return amountFormat((a.div(10 ** dec)).toString(), parseInt(dec));
    }

    const init = async () => {
        try {
            setIsLoading(false);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).MANUAL_STAKING_ABI, manualStakingAddress);
            let dividend = new BigNumber(await staking.methods.dividendOf(state.account).call());
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            const divBNB = dividend.div(10 ** dec);
            setDividendBNB(amountFormat(divBNB, 18));
            setDividendBSAFU(await getEstimation(getAppByChainId(state.chainId).TOKEN_ADDRESS, dividend.toString()));
            setDividendBUSD(await getEstimation(getAppByChainId(state.chainId).BUSD_ADDRESS, dividend.toString()));
            setDividendBTCB(await getEstimation(getAppByChainId(state.chainId).BTCB_ADDRESS, dividend.toString()));
            setDividendBWETH(await getEstimation(getAppByChainId(state.chainId).BETH_ADDRESS, dividend.toString()));
            setDividendWeboo(await getEstimation(getAppByChainId(state.chainId).WEBOO_ADDRESS, dividend.toString()));


        } catch (e) {
            // console.error("Init Earn Staking", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onClaimReward = async (targetToken) => {
        try {
            setIsLoading(true)
            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            if (targetToken) {
                await routerStaking.methods.claimToOther(manualStakingAddress, state.account, targetToken).send({
                    from: state.account
                }).on("transactionHash", (hash) => setTxHash(hash))
                    .on("receipt", (hash) => {
                        setTxHash(hash);
                        setIsLoading(false);
                    });
            } else {
                await routerStaking.methods.claimToEth(manualStakingAddress, state.account).send({
                    from: state.account
                }).on("transactionHash", (hash) => setTxHash(hash))
                    .on("receipt", (hash) => {
                        setTxHash(hash);
                        setIsLoading(false);
                    });
            }
        } catch (e) {
            console.error("Claim BSAFU", e)
        } finally {
            await init();
            // setIsLoading(false);
        }
    }

    return (
        <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
            <div className="block w-full">
                <div className="py-3">
                    <p className="text-sm font-semibold text-gray-900 dark:text-white"><span className="text-blue-500">BSAFU</span> EARNED</p>
                    <div className="flex justify-between item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">{dividendBSAFU}</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/bsafu.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BSAFU
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).TOKEN_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BNB */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBNB}</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/bnb.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BNB
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward()} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BTCB */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBTCB}</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/btc.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BTCB
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BTCB_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BUSD */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBUSD}</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/busd.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BUSD
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BUSD_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BETH */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBWETH}</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/eth.svg" className="w-4 h-4 rounded-full" alt="" />
                                        ETH
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BETH_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* WEBOO */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">Est: {dividendWeboo}</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/weboo.svg" className="w-4 h-4 rounded-full" alt="" />
                                        WEBOO
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).WEBOO_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    {/* <p className="text-sm font-semibold text-gray-900 dark:text-white"><span className="text-blue-500">BUSD</span> EARNED</p> */}
                    {/* <div className="flex items-center justify-between">
                        <div className="w-2/3">
                            <div className="block">
                                <h1 className="font-semibold text-gray-900 dark:text-white">0</h1>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">0
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/busd.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BUSD
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"><GiReceiveMoney /> Collect</button>
                            </div>
                        </div>
                    </div> */}

                    {/* <p className="mt-3 text-sm font-semibold text-gray-900"><span className="text-blue-500">{coinselected.name}</span> EARNED</p>
                    <div className="items-center justify-between block md:flex lg:flex"> 
                        <div className="w-full mt-3 md:w-2/3 lg:w-2/3 md:mt-0 lg:mt-0">
                            <Listbox value={coinselected} onChange={setcoinSelected}>
                                <div className="relative">
                                <Listbox.Button className="relative flex items-center w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-400 rounded-lg cursor-default dark:border-gray-800 dark:bg-gray-800 sm:text-sm">
                                    <img src={"images/icon-reward/" + coinselected.logo} className="w-5 h-5 mr-2" alt="" />
                                    <span className="block text-gray-900 truncate dark:text-white">5,000,000 {coinselected.name}</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <BsChevronExpand
                                            className="w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-gray-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {coin.map((person, personIdx) => (
                                        <Listbox.Option
                                        key={personIdx}
                                        className={({ active }) =>
                                            `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                            active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
                                            }`
                                        }
                                        value={person}
                                        >
                                        {({ selected }) => (
                                            <div className="flex items-center justify-start">
                                                <img src={"images/icon-reward/" + person.logo} className="w-5 h-5 mr-2" alt="" />
                                                <span
                                                    className={`block truncate text-gray-900 dark:text-white ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                                >
                                                    {person.name}
                                                </span>
                                                {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                                                        <BsCheck className="w-5 h-5 text-gray-700 dark:text-gray-100" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </div>
                                        )}
                                        </Listbox.Option>
                                    ))}
                                    </Listbox.Options>
                                </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="w-full mt-4 md:w-1/3 lg:w-1/3 md:mt-0 lg:mt-0">
                            <div className="flex items-center justify-end">
                                <button className="flex items-center justify-center w-full gap-2 px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:w-auto lg:w-auto"><GiReceiveMoney /> Collect</button>
                            </div>
                        </div>
                    </div> */}
                </div>
                <ModalTxHash txHash={txHash} />
            </div>
        </div>
    )
}

export default function Staking() {
    const [coinselected, setcoinSelected] = useState({ name: 'BSAFU', logo: 'bsafu.svg' })
    const [totalStacked, setTotalStacked] = useState(0);
    const [totalStackedManual, setTotalStackedManual] = useState(0);
    const [totalContributor, setTotalContributor] = useState(0)
    const [totalContributorManual, setTotalContributorManual] = useState(0)
    const [autoStakingAddress, setAutoStakingAddress] = useState(0);
    const [manualStakingAddress, setManualStakingAddress] = useState(0);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [txHash, setTxHash] = useState(null);

    useEffect(async () => {
        if (state && state.web3) {
            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const autoAddress = await routerStaking.methods.autoStakingAddress().call();
            const manualAddress = await routerStaking.methods.manualStakingAddress().call();
            setAutoStakingAddress(autoAddress);
            setManualStakingAddress(manualAddress);

            try {
                const autoContributor = await axios.get(`https://api.covalenthq.com/v1/56/tokens/${autoAddress}/token_holders/?&key=ckey_d2e4a992aa174572b0609a1f235`);
                setTotalContributor(autoContributor.data.data.items.length);
                const manualContributor = await axios.get(`https://api.covalenthq.com/v1/56/tokens/${manualAddress}/token_holders/?&key=ckey_d2e4a992aa174572b0609a1f235`);
                setTotalContributorManual(manualContributor.data.data.items.length);
            } catch (e) {
                console.error(e)
            }

        }
    }, [state])

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                                Stake <span className="text-blue-500">BSAFU</span> Choose Your Staking Reward Token
                            </div>
                            <div className="grid grid-cols-1 mt-4 md:mt-16 lg:mt-20 md:grid-cols-12">
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Choose Your Staking <br /> Reward Token</p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Stake BSAFU With <br /> Simple Interest Method</p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Stake BSAFU With <br /> Compounding Method</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img src="./images/stake-illustration.svg" className="w-9/12" width="438" height="396" alt="" />
                            </div>
                        </div>
                    </section>
                </section>
                <div
                    className={
                        "py-5 w-full bg-white dark:bg-gray-900 gap-4 flex items-center justify-center"
                    }
                >
                    <button className="px-5 py-2 text-white bg-gray-900 rounded-full">
                        All Stake
                    </button>
                    <button className="px-5 py-2 text-white bg-blue-700 rounded-full">
                        My Stake Only
                    </button>
                </div>
                <div className="p-4 bg-gray-100 dark:bg-gray-800 md:p-10 lg:p-10">
                    <div className="content-center px-1 md:20 lg:px-32">
                        <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-2">
                            <div className="w-full mx-auto">
                                <div className="border-2 border-blue-500 rounded-2xl">
                                    {/* <div class="bg-red-600 rounded-lg text-center py-5 px-6 mb-4 mt-4 ml-4 mr-4 text-base text-red-700 mb-3 w-100" role="alert">
                                        <span className="text-white">
                                            Sorry, Undermaintenance
                                        </span>
                                    </div> */}
                                    <div className="flex justify-between w-full p-6 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl">
                                        <div className="block">
                                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Compounding</h3>
                                            <p className="font-normal text-gray-700 dark:text-gray-100">Automatic Restaking <br /></p>
                                        </div>
                                        <div className="">
                                            <img src="images/icon-reward/bsafu.svg" className="border border-gray-200 rounded-full w-14" width="54" height="54" alt="" />
                                            <FiRefreshCw className="absolute z-10 w-8 h-8 p-2 ml-8 -mt-5 text-white bg-blue-500 border border-gray-200 rounded-full" alt="" />
                                        </div>
                                    </div>
                                    <div className="w-full px-6 py-3 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">

                                        <FormCompounding onGetTotalStacked={(e) => setTotalStacked(e)} />
                                    </div>

                                    <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-b-2xl">
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center justify-start gap-2">
                                                            <span className="flex items-center gap-1 px-3 py-1 text-sm font-semibold text-green-800 border border-green-500 rounded-full"><FiRefreshCw />Auto</span>
                                                            <BsQuestionCircle />
                                                        </div>
                                                        <Disclosure.Button className="flex items-center gap-1 font-semibold text-green-800">
                                                            {open ? "Hide" : "Details"} <BsChevronDown className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-green-800 font-semibold`} />
                                                        </Disclosure.Button>
                                                    </div>
                                                    <Disclosure.Panel className="py-2 text-gray-900">
                                                        <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Contributors:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalContributor}</p>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Staked:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalStacked} BSAFU</p>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Contract Address:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">
                                                                <CopyAddress addr={autoStakingAddress} />
                                                            </p>
                                                        </div>

                                                        {/* <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Performance Fee:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">2%</p>
                                                        </div> */}
                                                        {/* <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Contract Address:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">{autoStakingAddress}</p>
                                                        </div> */}
                                                        {/* <div className="block pt-2">
                                                            <div className="flex justify-end">
                                                                <a href="/" className="flex items-center gap-1 text-sm font-semibold text-right text-green-500">See Token Info <RiShareBoxFill /></a>
                                                            </div>
                                                            <div className="flex justify-end">
                                                                <a href="/" className="flex items-center gap-1 text-sm font-semibold text-right text-green-500">View Project Site <RiShareBoxFill /></a>
                                                            </div>
                                                            <div className="flex justify-end">
                                                                <a href="/" className="flex items-center gap-1 text-sm font-semibold text-right text-green-500">View Contract <RiShareBoxFill /></a>
                                                            </div>
                                                        </div> */}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mx-auto">
                                <div className="border-2 border-gray-300 dark:border-gray-800 rounded-2xl">
                                    <FormManualStaking onGetTotalStacked={(e) => setTotalStackedManual(e)} />
                                    <EarnManualStaking />
                                    <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-b-2xl">
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center justify-start gap-2">
                                                            <span className="flex items-center gap-1 px-3 py-1 text-sm font-semibold text-blue-500 border border-blue-500 rounded-full"><FiRefreshCw />Manual</span>
                                                            <BsQuestionCircle />
                                                        </div>
                                                        <Disclosure.Button className="flex items-center gap-1 font-semibold text-green-800">
                                                            {open ? "Hide" : "Details"} <BsChevronDown className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-green-800 font-semibold`} />
                                                        </Disclosure.Button>
                                                    </div>
                                                    <Disclosure.Panel className="py-2 text-gray-900">
                                                        <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Contributors:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalContributorManual}</p>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Staked:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalStackedManual} BSAFU</p>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Contract Address:</h6>
                                                            <p className="text-sm font-semibold text-gray-900 dark:text-white">
                                                                <CopyAddress addr={manualStakingAddress} />
                                                            </p>
                                                        </div>
                                                        {/* <div className="block pt-2">
                                                            <div className="flex justify-end">
                                                                <a href="/" className="flex items-center gap-1 text-sm font-semibold text-right text-green-500">See Token Info <RiShareBoxFill /></a>
                                                            </div>
                                                            <div className="flex justify-end">
                                                                <a href="/" className="flex items-center gap-1 text-sm font-semibold text-right text-green-500">View Project Site <RiShareBoxFill /></a>
                                                            </div>
                                                            <div className="flex justify-end">
                                                                <a href="/" className="flex items-center gap-1 text-sm font-semibold text-right text-green-500">View Contract <RiShareBoxFill /></a>
                                                            </div>
                                                        </div> */}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
