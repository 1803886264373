import { Tab, Transition } from "@headlessui/react";
import axios from "axios";
import BigNumber from "bignumber.js";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    BtnWalletConnect,
    CopyAddress,
    CopyButton,
    Empty,
    Footer,
    Header,
    LoadingWeboo,
    ModalTxHash,
} from "../../components/bsafu-ui";
import { ButtonLoading } from "../../components/Forms";
import { getAppByChainId } from "../../libs/Env";
import { Context } from "../../Store";
import { useGetAirdropDetail, usePostClaim } from "./hook";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function truncate(str) {
  return (str.length > 16) ? str.substr(0, 10) + '....' + str.substr(32, 35) : str;
};

const TabTokenInfo = ({row}) => {
  return (
    <>
      <div className="p-3">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <span className="font-bold">Contract Address</span>
          </div>
          <div className="flex items-center justify-start">
            <a href={"https://bscscan.com/token/" + row?.data?.result?.contract_address}>
              {truncate(row?.data?.result?.contract_address)}
            </a>
            <CopyButton text={row?.data?.result?.contract_address} />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2 md:flex-row lg:flex-row">
          <div className="w-full md:w-1/2 lg:w-1/2">
            <div className="flex flex-row items-center gap-10 mt-3">
              <span className="flex-initial w-32 text-sm font-bold">Name</span>
              <span className="text-sm font-semibold">{row?.data?.result?.contract_name}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Symbol
              </span>
              <span className="text-sm font-semibold">{row?.data?.result?.contract_symbol}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Decimal
              </span>
              <span className="text-sm font-semibold">{row?.data?.result?.contract_decimal}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Total Supply
              </span>
              <span>
              <span className="text-sm font-semibold">{row?.data?.result?.contract_supply}</span>{" "}
                <span className="text-sm font-semibold">
                <span className="text-sm font-semibold">{row?.data?.result?.contract_symbol}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TabAirdropInfo = ({row}) => {
  return (
    <>
      <div className="p-3">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <span className="font-bold">Contract Address</span>
          </div>
          <div className="flex items-center justify-start">
            <a href={"https://bscscan.com/token/" + row?.data?.result?.contract_address}>
              {truncate(row?.data?.result?.contract_address)}
            </a>
            <CopyButton text={row?.data?.result?.contract_address} />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2 md:flex-row lg:flex-row">
          <div className="w-full md:w-1/2 lg:w-1/2">
            <div className="flex flex-row items-center gap-10 mt-3">
              <span className="flex-initial w-32 text-sm font-bold">Start</span>
              <span className="text-sm font-semibold">{row?.data?.result?.start_at?.label_time} UTC</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                End
              </span>
              <span className="text-sm font-semibold">{row?.data?.result?.end_at?.label_time} UTC</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Total Airdrop
              </span>
              <span className="text-sm font-semibold">{row?.data?.result?.total_bounty} {row?.data?.result?.contract_symbol}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Airdrop Per User
              </span>
              <span className="text-sm font-semibold">{row?.data?.result?.peruser_bounty} {row?.data?.result?.contract_symbol}</span>
            </div>
            <div className="flex flex-row items-center gap-10 mt-1">
              <span className="flex-initial w-32 text-sm font-bold">
                Status
              </span>
              <span className="text-sm font-semibold">{row?.data?.result?.status}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const BountyInformation = ({ row, onSuccessClaim=(e)=>{}}) => {
  const [state, dispatch] = useContext(Context); 
  const [{response,txHash,isLoading,errResponse},postClaim] = usePostClaim();
  const [isAllowBalance,setIsAllowBalance] = useState(true)
  const [ip,setIp] = useState("");
  const onClaim = () => {
    postClaim(
      row?.data?.result?.id,
      ip,
      state.account
    )
  }

  const checkBalance = async() => {
    if(row.data.result.is_new_wallet === 1){
      const contract = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, row.data.result.contract_address); 
      let balance = new BigNumber(await contract.methods.balanceOf(state.account).call());
      // console.log(balance.isGreaterThan(0))
      if(balance.isGreaterThan(0)) setIsAllowBalance(false)
      else setIsAllowBalance(true)
    } else {
      setIsAllowBalance(true) 
    }
  }

  useEffect(()=>{
    if(!errResponse) return ;
      toast.error(errResponse, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
  },[errResponse])

  useEffect(()=>{
    if(!response) return ;
    onSuccessClaim(true)
  },[response])

  useEffect(async()=>{
    if(state && state.web3){
      await checkBalance()
    }
    getIpAddress2();
  },[])

  const getIpAddress2 = () => {
    axios.get('https://iptools-4.top10vpn.com/ip/?genToken=1')
      .then((res)=>{
        setIp(res.data.ip)
      });
  }


  return (
    <>
      <div className="p-4 bg-white border-2 border-gray-300 rounded-md dark:border-gray-800">
        <span className="text-xl font-bold">Claim</span>
        <hr className="my-2" />
        <ModalTxHash txHash={txHash} />
        <BtnWalletConnect>
              <div className="flex flex-col w-full">
              <div className="flex flex-col gap-1 mt-1">
                  <span className="text-lg text-dark-500">Status</span>
                  <span className="text-lg font-bold text-green-600 text-dark-500">
                  {row?.data?.result?.status}
                  </span>
                </div>
                <div className="flex flex-col gap-1 mt-1">
                  <span className="text-lg text-dark-500">Total Participant</span>
                  <span className="text-lg font-bold text-green-600 text-dark-500">
                  {row?.data?.result?.total_participant}
                  </span>
                </div>
                <div className="flex flex-col gap-1 mt-1">
                  <span className="text-lg text-dark-500">Total Claim</span>
                  <span className="text-lg font-bold text-green-600 text-dark-500">
                  {row?.data?.result?.total_claimed} {row?.data?.result?.contract_symbol}
                  </span>
                </div>
                {
                  row?.data?.result?.status !== "Live" || !row?.data?.result?.is_can_claim && (
                    <ButtonLoading title={"Claim"} isDisabled={true} className="w-full my-1 bg-gray-300 hover:bg-gray-300" onClick={()=>{}} />
                  )
                }
                {
                  row?.data?.result?.status === "Live" && row?.data?.result?.is_can_claim && isAllowBalance  &&(
                    <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} className="w-full my-1 bg-gray-900" onClick={()=>{onClaim()}} />
                  )
                }
                {
                  row?.data?.result?.status === "Live" && row?.data?.result?.is_can_claim && !isAllowBalance  &&(
                    <ButtonLoading title={"This Faucet Only For New Wallet"} isDisabled={true} className="w-full my-1 bg-red-600" onClick={()=>{}} />
                  )
                }
                
              </div>
            </BtnWalletConnect>
      </div>
    </>
  );
};

export default function AirdropDetail() {
    const [state, dispatch] = useContext(Context);
    let params = useParams();
    const [{response,isLoading,errResponse},submit] = useGetAirdropDetail(params.id)
    const [tabSelected,setTabSelected] = useState(0);
    useEffect(async ()=>{
        if(state && state.web3){
            
            
        }
    },[state])
    
    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body"  style={{
                      background: 'linear-gradient(90deg,#00519d,#102a85)'
                    }}>
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                              {response?.data?.result?.project_name}
                            </div>
                            <div className="text-xl text-white">
                              {response?.data?.result?.short_description}
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img src="./images/bounty-scammer-logo.svg" className="w-9/12" alt="" />
                            </div>
                        </div>
                    </section>
                </section>
                
                <div className="w-full bg-gray-100 p-7 dark:bg-gray-800 md:p-10 lg:p-10">
                    <div className="container w-full md:mx-20">
                      {isLoading && <LoadingWeboo /> }
                      {!isLoading && !response && <Empty text={"No Airdrop Found"} /> }
                      {!isLoading && response && (
                        <div className="flex flex-col gap-2 md:flex-row lg:flex-row">
                          <div className="w-full md:w-3/4 lg:w-3/4">
                            <Tab.Group>
                              <Tab.List className="flex p-1 space-x-1 rounded-md bg-blue-900/20">
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-dark",
                                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected
                                        ? "bg-white shadow"
                                        : "text-dark hover:bg-white/[0.12] hover:text-blue-500"
                                    )
                                  }
                                >
                                  Project Info
                                </Tab>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-dark",
                                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected
                                        ? "bg-white shadow"
                                        : "text-dark hover:bg-white/[0.12] hover:text-blue-500"
                                    )
                                  }
                                >
                                  Airdrop Info
                                </Tab>
                                {/* <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-dark",
                                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected
                                        ? "bg-white shadow"
                                        : "text-dark hover:bg-white/[0.12] hover:text-blue-500"
                                    )
                                  }
                                >
                                  Airdrop History
                                </Tab> */}
                              </Tab.List>
                              <Tab.Panels className="mt-2">
                                <Tab.Panel
                                  className={classNames(
                                    "rounded-md bg-white p-3 border-2",
                                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                                  )}
                                >
                                  <TabTokenInfo row={response} />
                                </Tab.Panel>
                                <Tab.Panel
                                  className={classNames(
                                    "rounded-md bg-white p-3 border-2",
                                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                                  )}
                                >
                                  <TabAirdropInfo row={response} />
                                </Tab.Panel>
                              </Tab.Panels>
                            </Tab.Group>
                          </div>
                          <div className="w-full md:w-1/4 lg:w-1/4">
                            <BountyInformation row={response} onSuccessClaim={(e)=>submit} />
                          </div>
                        </div>
                      )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
