import axios from "axios";
import { useEffect, useState } from "react";
import { getEnv } from "../../libs/Env";

export const useGetAirdropList = () => {
  const url = getEnv().WEBOOSAFU_API+"/api/bounty-airdrop";

  const [response,setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errResponse,setErrResponse] = useState(null);

  const getData = async(params=null,toast=null) => {
      try {
          setIsLoading(true);
          const result = await axios(url,{
              method: "GET",
              headers: {
                  Accept: 'application/json',
                  Authorization: 'Bearer '+getEnv().WEBOOSAFU_ACCESS_TOKEN
              },
          })
          if(result.data.code === 200) setResponse(result);
          else if(result.data.code === 403) alert("Forbidden");
      } catch (e){
          setErrResponse(e.response);
      } finally {
          setTimeout(()=>{
              setIsLoading(false)
          },2000)
      }
  }

  useEffect(async()=>{
    getData()      
  },[])

  return [{response,isLoading,errResponse},getData];
}

export const useGetAirdropDetail = (id) => {
    const url = getEnv().WEBOOSAFU_API+"/api/bounty-airdrop/"+id;
  
    const [response,setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errResponse,setErrResponse] = useState(null);
  
    const getData = async(params=null,toast=null) => {
        try {
            setIsLoading(true);
            const result = await axios(url,{
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer '+getEnv().WEBOOSAFU_ACCESS_TOKEN
                },
            })
            if(result.data.code === 200) setResponse(result);
            else if(result.data.code === 403) alert("Forbidden");
        } catch (e){
            setErrResponse(e.response);
        } finally {
            setTimeout(()=>{
                setIsLoading(false)
            },2000)
        }
    }
  
    useEffect(async()=>{
      getData()      
    },[])
  
    return [{response,isLoading,errResponse},getData];
}

export const usePostClaim = (id) => {
    const url = getEnv().WEBOOSAFU_API+"/api/bounty-airdrop-claim";
    const [response,setResponse] = useState(null);
    const [txHash, setTxhash] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errResponse,setErrResponse] = useState(null);
  
    const postClaim = async(airdrop_id, ip, user_address) => {
        try {
            setIsLoading(true);
            const result = await axios.post(url,{
                bounty_airdrop_id: airdrop_id,
                user_address: user_address,
                ip: ip,
            },{
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer '+getEnv().WEBOOSAFU_ACCESS_TOKEN
                },
            })
            if(result.data.code === 200) {
                setResponse(result);
                setTxhash(result?.data?.result?.txhash)
            }
            else  {
                throw result.data.message
            }
        } catch (e){
            setErrResponse(e);
        } finally {
            setTimeout(()=>{
                setIsLoading(false)
            },2000)
        }
    }
  
  
    return [{response,txHash,isLoading,errResponse},postClaim];
}