import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AirdropDetail from "./pages/Airdrop/AirdropDetail";
// import AirdropCreatePool from "./pages/AirdropCreatePool";
import AirdropList from "./pages/Airdrop/AirdropList";
import BountyScammer from "./pages/BountyScammer";
import BountyScammerDetail from "./pages/BountyScammerDetail";
import BountyScammerRegister from "./pages/BountyScammerRegister";
import Referral from "./pages/Referral";
import ReferralMember from "./pages/ReferralMember";
// import Home from "./pages/Home";
import Staking from "./pages/Staking";
import Store from "./Store";

export default function Navigation() {
    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Staking />} />
                    <Route path={"/referral"} element={<Referral />} />
                    <Route path={"/referral-member"} element={<ReferralMember />} />
                    <Route path={"/bounty-scammers"} element={<BountyScammer />} />
                    <Route path={"/bounty-scammers/:id"} element={<BountyScammerDetail />} />
                    <Route path={"/register-bounty"} element={<BountyScammerRegister />} />
                    <Route path={"/faucet-list"} element={<AirdropList />} />
                    <Route path={"/faucet/:id"} element={<AirdropDetail />} />
                    {/* <Route path={"/airdrop-create-pool"} element={<AirdropCreatePool />} /> */}
                </Routes>
            </BrowserRouter>
        </Store>
    )
}